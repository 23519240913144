.ant-modal-footer {
  display: none;
}

.fixed-height {
  height: 400px;
  display: flex;
  flex-direction: column;
}

.fixed-height .ant-table-body {
  max-height: 300px;
  overflow-y: auto;
}
