.form-create {
    width: 100%;
    display: flex;
    justify-content: center;
}

.payment-search {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-top: 20px;
    padding-right: 16px;
}

.ant-table-row .ant-table-row-level-0 {
    display: flex;
}
 /* .ant-table-cell{
    display: flex;
    align-items: center;
    justify-content: center;
} */

.ant-select-dropdown {
    z-index: 1700 !important;
}

.modal-all .modal-dialog {
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 35%;
}

.modal-all .modal-dialog .modal-header {
    border-bottom: 0;
}

.modal-all .modal-dialog .modal-body {
    text-align: center;
    font-weight: 400;
}

.modal-all .modal-dialog .modal-footer {
    justify-content: center;
    border-top: 0;
}