.menu-icon svg{
    font-size: 18px !important;
}

a {
    text-decoration: none;
}

.ant-menu-submenu-title {
    padding-left: 16px !important;
}

.copy-icon svg{
    font-size: 15px !important;
    cursor: pointer;
    margin-left: 10px;
}