.header-cus {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 16px;
}

.header-right {
    display: flex;
    align-items: center;
}

.img-logout {
    width: 18px;
    height: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.time-system {
    display: flex;
    margin-right: 50px;
}
