.order-detail {
    display: flex;
    justify-content: center;
}

.order-detail .content .flex-space-between {
    display: flex;
    justify-content: space-between;
}
.order-detail .content .label {
    color: black;
    font-weight: 500;
    font-size: 13px;
}

.order-detail .result {
    white-space: pre-line;
    text-align: center;
}

.custom-break {
	word-break: break-all;
}
.fs-13 {
    font-size: 13px;
}