/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

tr,
td,
th {
  text-align: center !important;
}

.modal-all .modal-dialog {
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35%;
}

.modal-all .modal-dialog .modal-header {
  border-bottom: 0;
}

.modal-all .modal-dialog .modal-body {
  text-align: center;
  font-weight: 400;
}

.modal-all .modal-dialog .modal-footer {
  justify-content: center;
  border-top: 0;
}

.ant-menu-item-group-title {
  font-size: 12px !important;
}