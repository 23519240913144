.roles .edit {
    margin-right: 10px;
}

.group-roles {
    border-bottom: 1px solid #ccc;
}

.right {
    margin-left: 30px;
}

.search-roles {
    justify-content: end;
}