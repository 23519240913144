* {
    font-size: 14px;
}

.body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
    font-size: 18px;
}

.site-body {
    background: #fff !important;
    min-height: 100vh;
    width: 250px !important;
    max-width: 250px !important;
    /* min-width: 250px !important; */
    font-weight: 600;
}

.site-layout .site-layout-background {
    background: #fff;
}
