.div-game {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.div-game-child {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid;
    border-style: dashed;
    border-radius: 20px;
    margin: 5px;
}

.text-game {
    font-weight: 600;
    text-transform: uppercase;
}

.sts-game {
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}