.custom-input {
  padding: 4px 11px;
  outline: none;
  border: 1px solid;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  width: 90%;
}

.custom-input:hover {
  border-color: #1677ff;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}

.mb-custom {
  margin-bottom: 0px !important;
}